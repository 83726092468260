/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  createStyles,
  Popover,
  Typography,
  Tooltip,
  Button,
  IconButton,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import ButtonBase from '@material-ui/core/ButtonBase'
import { useTypedSelector } from '../../../reducers'
import { useDispatch } from 'react-redux'
import CreditsIcon from '../../icons/CreditsIcon'
import {
  ArrowRightAlt,
  Close,
  Input,
  Refresh,
  WarningSharp
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { theme } from '../../../styles/theme'
import { useIntl } from 'react-intl'
import { callCreditAvailableGet } from '../../../actions/credits'
import moment from 'moment'

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: (props: IProps) => ({
      cursor: props.disabled ? 'default' : 'pointer',
      paddingRight: theme.spacing(2)
    }),
    popover: {
      maxWidth: '75vw'
    },
    popper: {
      maxWidth: 'none'
    },
    content: {
      padding: theme.spacing(2),
      background: '#dddddd'
    },
    padIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.secondary.main
    },
    innerButton: {
      width: 'max-content',
      marginTop: 0,
      marginBottom: 0
    },
    popHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    closeButton: {
      margin: '-7px'
    },
    innerBlock: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      background: 'white',
      borderRadius: '5px'
    },
    messageBlock: {
      display: 'grid',
      gap: 'auto',
      gridTemplateColumns: '1fr 24px 1fr',
      alignItems: 'center',
      width: '100%'
    }
  })
})

interface IProps {
  disabled?: boolean
}

const CreditsInfo = (props: IProps) => {
  const classes = useStyles(props)
  const { disabled } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    !disabled && setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const intl = useIntl()
  const open = Boolean(anchorEl)
  const id = open ? 'context-help' : undefined
  // const creditDetailsExt = useTypedSelector(
  //   state => state.credits.creditDetailsExt
  // )

  const isAdmin = useTypedSelector(state => state.admin.isAdmin) || false

  const creditAvailableInfoData = useTypedSelector(
    state => state.credits.creditAvailableInfoData
  )
  const creditAvailable = useTypedSelector(
    state => state.credits.creditAvailable
  )

  const creditLoading = useTypedSelector(state => state.credits.loadingState)
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId) || null
  const dispatch = useDispatch()
  // const [credInfo, setCredInfo] = useState<ICreditsDetail>()
  const [expirationData, setExpirationData] = useState<any[]>([])
  const [reload, setReload] = useState<boolean>(false)
  const [expDate, setExpDate] = useState<string>('--')
  const [expCredits, setExpCredits] = useState<number>(0)
  const history = useHistory()

  const validateDate = (sourceDate, transparent = false) => {
    const endD = moment()
    const startD = moment(sourceDate).add('days', 1)
    const duration = moment.duration(startD.diff(endD))
    if (duration.asDays() <= 10) return 'red'
    if (duration.asDays() <= 30) return 'orange'
    return transparent ? 'transparent' : theme.palette.secondary.main
  }

  //VSE: 'Available, Reserved, ExpiredInUse, Spent, Expired, Repaid'
  useEffect(() => {
    if (tenantId) {
      dispatch(callCreditAvailableGet(tenantId))
    }
  }, [tenantId, reload])

  const formatDate = (dateIn: string) => {
    return moment(dateIn).format('DD MMM YYYY')
  }

  useEffect(() => {
    if (
      creditAvailableInfoData &&
      creditAvailableInfoData.data &&
      creditAvailableInfoData.data.length > 0
    ) {
      const expiration = creditAvailableInfoData.data[0]?.expiresAt ?? '0'
      setExpDate(formatDate(expiration))
      let sumData = creditAvailableInfoData.data.reduce(
        (total, item) =>
          item.expiresAt === expiration ? total + item.credits : total,
        0
      )
      setExpCredits(sumData)
      setExpirationData(creditAvailableInfoData.data.slice(0, 5))
    }
  }, [creditAvailableInfoData, creditAvailable])

  const addspace = (num, separator) => {
    let mainString = num.toString()
    mainString = mainString
      .split('')
      .reverse()
      .join('')

    mainString = mainString.match(/.{1,3}/g).join(separator)
    mainString = mainString
      .split('')
      .reverse()
      .join('')

    return mainString
  }

  return (
    <>
      <Tooltip
        title={
          <div>
            <Typography>
              {addspace(Math.round(creditAvailable), '  ') +
                intl.formatMessage({
                  id: 'credits.creditsInfo.tooltip2'
                })}
            </Typography>
            <Typography
              style={{ color: validateDate(expDate), width: 'max-content' }}
            >
              {addspace(Math.round(expCredits), '  ') +
                intl.formatMessage({
                  id: 'credits.creditsInfo.tooltip3'
                }) +
                expDate}
            </Typography>
          </div>
        }
        classes={{ tooltip: classes.popper }}
      >
        <ButtonBase
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
        >
          <WarningSharp style={{ color: validateDate(expDate, true) }} />
          <CreditsIcon />
          <Typography variant="h4" color="secondary">
            {addspace(Math.round(creditAvailable), '  ')}
          </Typography>{' '}
        </ButtonBase>
      </Tooltip>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.content}>
          <div className={classes.popHeader}>
            <Typography variant="h5" style={{ color: 'primary' }}>
              {intl.formatMessage({
                id: 'credits.creditsInfo.creditsButton'
              })}
            </Typography>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
              size="small"
            >
              <Close />{' '}
            </IconButton>
          </div>
          <div className={classes.innerBlock}>
            <Button
              className={classes.innerButton}
              startIcon={<Refresh className={classes.padIcon} />}
              variant="text"
              onClick={event => {
                event.stopPropagation()
                setReload(!reload)
              }}
            >
              {intl.formatMessage({
                id: 'credits.creditsInfo.remainingCreditsButton'
              }) +
                Number(parseFloat(creditAvailable).toFixed(2)).toLocaleString(
                  'fr'
                )}
            </Button>
            <Typography
              variant="body2"
              style={{
                fontWeight: 'bold',
                color: theme.palette.secondary.main,
                marginBottom: theme.spacing(1)
              }}
            >
              {intl.formatMessage({
                id: 'credits.creditsInfo.nearestExp'
              })}
            </Typography>
            <div
              style={{
                display: creditLoading ? 'flex' : 'none',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2)
              }}
            >
              <CircularProgress />
            </div>
            {!creditLoading &&
              expirationData &&
              expirationData?.map((item, index) => {
                return (
                  <div key={index}>
                    {index !== 0 && <Divider />}
                    <div key={index} className={classes.messageBlock}>
                      <Typography
                        variant="body2"
                        style={{ color: validateDate(item?.expiresAt) }}
                        noWrap
                      >
                        {Number((item?.credits).toFixed(2)).toLocaleString(
                          'fr'
                        ) + ' Credits '}
                      </Typography>
                      <ArrowRightAlt
                        style={{
                          color: validateDate(item?.expiresAt),
                          marginRight: '0.2em'
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={{ color: validateDate(item?.expiresAt) }}
                        noWrap
                      >
                        {formatDate(item?.expiresAt)}
                      </Typography>
                    </div>
                  </div>
                )
              })}

            {isAdmin && (
              <Button
                className={classes.innerButton}
                startIcon={<Input className={classes.padIcon} />}
                variant="text"
                onClick={event => {
                  event.stopPropagation()
                  setAnchorEl(null)
                  history.push(`/site-info/credits`)
                }}
              >
                {intl.formatMessage({
                  id: 'credits.creditsInfo.moreInfo'
                })}
              </Button>
            )}
          </div>
        </div>
      </Popover>
    </>
  )
}

export default CreditsInfo
