import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import SiteInfoNav from '../../SiteInfoNav'
import { useTypedSelector } from '../../../reducers'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CreditsStateEnum } from '../../../model/CreditsStateEnum'
import CreditsNav from './CreditsNav'
import { callCreditUnassignedDetailsPost } from '../../../actions/credits'
import DateTime from '../../DataTable/renders/DateTime'
import { getProject } from '../../../apis/admin'
import { IBillingReferenceCreditsDetailsInput } from '../../../apis/credits'
import IBillingInformation from '../../../model/IBillingInformation'
import { WarningSharp } from '@material-ui/icons'
import { Paper, Typography } from '@material-ui/core'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useSavedDataGridState } from '../../../hooks/use-saved-data-grid-state.hook'

const hoverStyle = () => css`
  &:hover {
    cursor: pointer;
  }
`

const validateDate = (state, transparent = false) => {
  if (state === 2) return 'red'
  if (state === 1) return 'orange'
  return transparent ? 'transparent' : 'inherit'
}

const validityRender = ({ value }) => (
  <div
    style={{
      marginLeft: '-2em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end'
    }}
  >
    <WarningSharp style={{ color: validateDate(value[1], true) }} />
    <Typography
      variant="body2"
      style={{ color: validateDate(value[1], false), marginLeft: '0.5em' }}
    >
      {value[0]}
    </Typography>
  </div>
)

const creditsRender = ({ value }) => {
  return (
    <span>
      {new Intl.NumberFormat('fr', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)}
    </span>
  )
}
const dateTimeRender = ({ value }) =>
  value ? <DateTime jsonTime={value} /> : '--'

const CreditsAllocation = () => {
  const intl = useIntl()
  const tenantId = useTypedSelector(state => state['auth']?.user?.tenantId)
  const creditLoading = useTypedSelector(state => state['credits'].loadingState)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [currentRows, setCurrentRows] = useState<number>(25)
  const [entries, setEntries] = useState<any>()

  const creditUnassignedExt = useTypedSelector(
    state => state['credits'].creditUnassignedExt
  )
  const [billingInformation, setBillingInformation] = useState<
    IBillingInformation
  >()

  const defaultColumns: GridColDef[] = [
    {
      field: 'credits',
      headerName: intl.formatMessage({
        id: 'credits.columns.amount'
      }),
      cellClassName: hoverStyle,
      renderCell: params => creditsRender(params),
      width: 840
    },
    {
      field: 'expiresAt',
      headerName: intl.formatMessage({
        id: 'credits.columns.expiredBy'
      }),
      cellClassName: hoverStyle,
      renderCell: params => dateTimeRender(params),
      width: 840
    },
    {
      field: 'validity',
      headerName: intl.formatMessage({
        id: 'credits.columns.timeLeft'
      }),
      cellClassName: hoverStyle,
      renderCell: params => validityRender(params),
      valueGetter: params => {
        // provides alternative value for sorting
        // because MUI does not understand sorting with array cell
        const expiresAtTimeValue = new Date(params.row.expiresAt).getTime()

        return [params.row.validity[0], expiresAtTimeValue]
      },
      sortComparator: (v1, v2) => (v1![1] as number) - (v2![1] as number),
      width: 830
    }
  ]

  const {
    apiRef,
    debounceStateSave,
    columnsArray,
    setColumnsArray
  } = useSavedDataGridState({
    tableKey: 'credits-allocation-settings',
    defaultColumns
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (tenantId) {
      getProject(tenantId).then(res => {
        setBillingInformation(res?.billingInformation)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])

  useEffect(() => {
    if (billingInformation) {
      const data: IBillingReferenceCreditsDetailsInput = {
        statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
        offset: 0,
        limit: 25,
        billingInformation: {
          billingReferenceTag: '',
          billingReference: billingInformation?.billingReference ?? '',
          billingReferenceType: billingInformation?.billingReferenceType ?? ''
        }
      }
      dispatch(callCreditUnassignedDetailsPost(data))
    }
  }, [billingInformation, dispatch])

  const onChangePage = page => {
    const properPage = Math.floor(page)

    const dataOffset = properPage * currentRows
    setCurrentPage(properPage)

    const data: IBillingReferenceCreditsDetailsInput = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: currentRows,
      billingInformation: {
        billingReferenceTag: '',
        billingReference: billingInformation?.billingReference || '',
        billingReferenceType: billingInformation?.billingReferenceType ?? ''
      }
    }
    dispatch(callCreditUnassignedDetailsPost(data))
  }

  const onChangeRowsPerPage = rowsPerPage => {
    setCurrentRows(rowsPerPage)
    const dataOffset = currentPage * currentRows
    setCurrentPage(dataOffset / rowsPerPage)
    const data: IBillingReferenceCreditsDetailsInput = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: rowsPerPage,
      billingInformation: {
        billingReferenceTag: '',
        billingReference: billingInformation?.billingReference || '',
        billingReferenceType: billingInformation?.billingReferenceType ?? ''
      }
    }
    dispatch(callCreditUnassignedDetailsPost(data))
  }

  function onCreditsChange() {
    const data: IBillingReferenceCreditsDetailsInput = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: currentPage * currentRows,
      limit: currentRows,
      billingInformation: {
        billingReferenceTag: '',
        billingReference: billingInformation?.billingReference || '',
        billingReferenceType: billingInformation?.billingReferenceType ?? ''
      }
    }
    dispatch(callCreditUnassignedDetailsPost(data))
  }

  useEffect(() => {
    setEntries(cloneDeep(creditUnassignedExt))
  }, [creditUnassignedExt])

  return (
    <>
      <SiteInfoNav />

      <CreditsNav
        displayState={CreditsStateEnum.ALLOCATION}
        columns={columnsArray}
        setColumns={setColumnsArray}
        onCreditsChange={onCreditsChange}
      />
      <div style={{ marginTop: '1em' }} />
      <Paper>
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          rows={entries?.data?.map(item => ({ ...item, id: uuidv4() })) ?? []}
          columns={columnsArray}
          pagination
          page={currentPage}
          pageSize={creditUnassignedExt?.limit ?? 0}
          rowCount={creditUnassignedExt?.totalCount ?? 0}
          paginationMode="server"
          getRowId={item => item.id}
          loading={creditLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          onStateChange={debounceStateSave}
        />
      </Paper>
    </>
  )
}

export default CreditsAllocation
