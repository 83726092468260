const readerWriterTranslations = {
  'import.readers.AsciiReader': 'Ascii grid file (.asc, .ascii)',
  'import.readers.AsciiReader.description': '(.asc, .ascii)',
  'import.readers.CopernicusGlobalAnalysisForecast':
    'Copernicus NetCDF file (.nc)',
  'import.readers.CopernicusGlobalAnalysisForecast.description':
    'Data from copernicus marine environment monitoring service',
  'import.readers.CsvZipReader': 'ZIP file of time series and metadata (.zip)',
  'import.readers.CsvZipReader.description':
    'ZIP file with time series in CSV format, corresponding metadata and a flagsdef file in JSON format (.zip)',
  'import.readers.CSVReader': 'File of comma seperated values (.csv)',
  'import.readers.CSVReader.description': '(.csv)',
  'import.readers.Dfs0Reader': 'Timeseries file (.dfs0)',
  'import.readers.Dfs0Reader.description': '(.dfs0)',
  'import.readers.Dfs1Reader': 'Profile series file (.dfs1)',
  'import.readers.Dfs1Reader.description': '(.dfs1)',
  'import.readers.Dfs2Reader': '2D Grid series file (.dfs2)',
  'import.readers.Dfs2Reader.description': '(.dfs2)',
  'import.readers.Dfs3Reader': '3D Grid series file (.dfs3)',
  'import.readers.Dfs3Reader.description': '(.dfs3)',
  'import.readers.DfsuReader': '2D or 3D unstructured data file (.dfsu)',
  'import.readers.DfsuReader.description': '(.dfsu)',
  'import.readers.FileReader': 'Unspecified file type',
  'import.readers.FileReader.description': 'Arbitrary file',
  'import.readers.GeoJsonReader': 'GeoJSON (.json)',
  'import.readers.GeoJsonReader.description': '(.json)',
  'import.readers.GeoTiffReader': 'GeoTIFF file (.tif, .tiff)',
  'import.readers.GeoTiffReader.description': '(.tif, .tiff)',
  'import.readers.GISReader': 'Gis vector data',
  'import.readers.GISReader.description': 'Data from GIS storage',
  'import.readers.HycomGLBa008Reader': 'Hycom glba0.08 file (.nc)',
  'import.readers.HycomGLBa008Reader.description':
    'NetCDF file containing hycom glba0.08 product',
  'import.readers.HycomGLBv008Reader': 'Hycom glbv0.08 file (.nc)',
  'import.readers.HycomGLBv008Reader.description':
    'NetCDF file containing hycom glbv0.08 product',
  'import.readers.HycomReader': 'Hycom file (.nc, .nc4)',
  'import.readers.HycomReader.description':
    'Netcdf file produced by Hycom - GLBa0.08, GLBv0.08, GLBy0.08',
  'import.readers.inputParameters.AllowedItemNames': 'Items to be imported',
  'import.readers.inputParameters.CoordinateInterpretationType':
    'Coordinate interpretation',
  'import.readers.inputParameters.QuantityMapItems': 'Quantity map items',
  'import.readers.inputParameters.ZColumnIndex': 'Z',
  'import.readers.inputParameters.ConnectColumnIndex': 'Connect',
  'import.readers.GHMReader':
    'ZIP file of Global hydrological model files (.zip)',
  'import.readers.GHMReader.description': '(.zip)',
  'import.readers.MeshFileReader': 'Mesh file (.mesh)',
  'import.readers.MeshFileReader.description': '(.mesh)',
  'import.readers.MDReader': 'Multidimensional dataset',
  'import.readers.MDReader.description': 'File in the multidimensional storage',
  'import.readers.NoaaGribReader':
    'Noaa Grib file (.grb, .grb2, .grib, .grib2)',
  'import.readers.NoaaGribReader.description':
    'GFS/CFS NOAA files in GRIB2 format',
  'import.readers.ShpReader': 'Shape file (.zip)',
  'import.readers.ShpReader.description': '(.zip)',
  'import.readers.TimeSeriesDfs0Reader': 'Timeseries file (.dfs0)',
  'import.readers.TimeSeriesDfs0Reader.description': '(.dfs0)',
  'import.readers.TimeSeriesReader': 'Timeseries storage reader',
  'import.readers.TimeSeriesReader.description':
    'Reads data from time series storage',
  'import.readers.XyzReader': 'XYZ text file (.xyz)',
  'import.readers.XyzReader.description':
    'Text file with columns of x and y coordinates and elevation values (.xyz)',
  'import.readers.VtuReader': 'VTK Unstructured Grid (.vtu)',
  'import.readers.VtuReader.description':
    'Visualization file (from Mesh Builder)',
  'import.readers.VtiReader': 'VTK Image Data (.vti)',
  'import.readers.VtiReader.description':
    'Visualization file (from Mesh Builder)',
  'import.writers.AsciiWriter': 'Ascii grid file (.asc, .ascii)',
  'import.writers.AsciiWriter.description': '(.asc, .ascii)',
  'import.writers.Dfs2Writer': '2D Grid series file (.dfs2)',
  'import.writers.Dfs2Writer.description': '(.dfs2)',
  'import.writers.DfsuWriter': '2D or 3D unstructured data file (.dfsu)',
  'import.writers.DfsuWriter.description': '(.dfsu)',
  'import.writers.file': 'Regular file (as-is)',
  'import.writers.file.description':
    'The file will be stored without modifications',
  'import.writers.FileWriter': 'Regular file (as-is)',
  'import.writers.FileWriter.description':
    'The file will be stored without modifications',
  'import.writers.GeoJsonWriter': 'GeoJSON (.json)',
  'import.writers.GeoJsonWriter.description':
    'Geographic data structures in json file',
  'import.writers.GeoTiffWriter': 'GeoTiff file (.tif, .tiff)',
  'import.writers.GeoTiffWriter.description': '(.tif, .tiff)',
  'import.writers.GISWriter': 'Gis vector data',
  'import.writers.GISWriter.description':
    'Queryable file format useable in applications such as Mesh Builder',
  'import.writers.MDWriter': 'Multidimensional dataset',
  'import.writers.MDWriter.description':
    'Upload the file to the multidimensional storage, which will allow you to do spatial and temporal queries',
  'import.writers.NetcdfWriter': 'NetCDF file (.nc)',
  'import.writers.NetcdfWriter.description': 'Network Common Data Form',
  'import.writers.ShpWriter': 'Shape file (.zip)',
  'import.writers.ShpWriter.description':
    'Geospatial vector data format for geographic information system (GIS) software',
  'import.writers.TilingWriter': 'Tiles',
  'import.writers.TilingWriter.description': 'Tiles',
  'import.writers.TimeSeriesCsvWriter':
    'ZIP file of time series and metadata (.zip)',
  'import.writers.TimeSeriesCsvWriter.description':
    'ZIP file with time series in CSV format and corresponding metadata in JSON format (.zip)',
  'import.writers.TimeSeriesDfs0Writer': 'Timeseries file (.dfs0)',
  'import.writers.TimeSeriesDfs0Writer.description': '(.dfs0)',
  'import.writers.TSWriter': 'Timeseries storage',
  'import.writers.TSWriter.description': 'Writes into timeseries storage',
  'import.writers.VtiWriter': 'VTK Image Data (.vti)',
  'import.writers.VtiWriter.description':
    'Visualization file format, often used in Mesh Builder',
  'import.writers.VtuWriter': 'VTK Unstructured Grid (.vtu)',
  'import.writers.VtuWriter.description':
    'Visualization file format, often used in Mesh Builder',
  'import.writers.outputParameters.UseSpatialIndex': 'Use spatial index',
  'import.writers.outputParameters.TimeSeriesProperties':
    'Time series properties',
  'import.writers.outputParameters.WriteAsFloat': 'Write as float',
  'import.writers.outputParameters.UseNetcdfVersion3': 'Use netcdf version 3',
  'import.writers.outputParameters.ItemMap': 'Item map'
}

export default readerWriterTranslations
