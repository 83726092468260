import React, { useMemo } from 'react'
import { Typography, Breadcrumbs, Tooltip } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IProject from '../../model/IProject'
import ProjectLink from '../ProjectLink'
import { useTypedSelector } from '../../reducers'

const useStyles = makeStyles(theme =>
  createStyles({
    mikeBreadcrumbsLinkLabel: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary
      },
      '&:active': {
        color: theme.palette.text.primary
      },
      '&:focus': {
        color: theme.palette.text.primary
      },
      maxWidth: '15vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    mikeBreadcrumbsLastLabel: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      maxWidth: '15vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      margin: '15px'
    }
  })
)

const AppBreadcrumbNav = () => {
  const projectPath = useTypedSelector<IProject[] | null>(
    state => state['project'].path.data
  )
  const tenantName =
    useTypedSelector(state => state['auth'].user?.tenantName) || ''

  const tenantId =
    useTypedSelector(state => state['auth'].user?.tenantId) || null
  const classes = useStyles()

  const path: IProject[] | null = useMemo(() => {
    if (projectPath && projectPath[0]?.id !== tenantId)
      return [
        ({
          id: tenantId,
          name: tenantName,
          parentProjectId: null,
          effectiveUserRole: ''
        } as unknown) as IProject,
        ...projectPath
      ]

    return projectPath
  }, [projectPath, tenantId, tenantName])

  return (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      {path &&
        path.map((item: IProject, index) => {
          const name = item.name
          const id = item.id
          const parentProjectId = item.parentProjectId
          const effectiveUserRole = item?.effectiveUserRole

          const first = index === 0
          const last = index === path.length - 1

          const shownName = first ? tenantName : name

          return (
            <Tooltip
              key={index + '_' + name}
              title={shownName + ` [${effectiveUserRole}]`}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem'
                }}
              >
                <Typography
                  variant="body2"
                  className={
                    last
                      ? classes.mikeBreadcrumbsLastLabel
                      : classes.mikeBreadcrumbsLinkLabel
                  }
                >
                  {last ? (
                    shownName
                  ) : (
                    <ProjectLink
                      text={shownName}
                      to={parentProjectId && id ? id : `/site`}
                    />
                  )}
                </Typography>
                <span style={{ fontSize: '14px' }}>
                  [
                  <span style={{ fontStyle: 'italic' }}>
                    {effectiveUserRole}
                  </span>
                  ]
                </span>
              </div>
            </Tooltip>
          )
        })}
    </Breadcrumbs>
  )
}

export default AppBreadcrumbNav
