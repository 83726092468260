import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, Divider, Typography } from '@material-ui/core'
import { getTenantUsers, getUsers } from '../../apis/admin'

type Props = {
  isMenuOpen: boolean
  classes: Record<'buttonSecondaryBorderWhite', string>
}

type GetTenantUsersResponseUser = {
  email: string
  name: string
  userIdentityId: string
}

type GetTenantUsersResponse = {
  users: GetTenantUsersResponseUser[]
  tenantId: string
}

type GetUsersResponseDataItem = {
  principalType: string
  userId: string
  role: string
}

type GetUsersResponse = {
  data: GetUsersResponseDataItem[]
}

type UseApiUsersReturnTypeApiUsers = {
  email: string
  name: string
}

type UseApiUsersReturnType = {
  apiUsers: UseApiUsersReturnTypeApiUsers[]
}

const useApiUsers = (): UseApiUsersReturnType => {
  const [apiUsers, setApiUsers] = useState([])

  useEffect(() => {
    const handleGetAndMergeUsers = async () => {
      const { tenantId, users }: GetTenantUsersResponse = await getTenantUsers()
      const { data }: GetUsersResponse = await getUsers(tenantId)

      const finalUsers = data
        .filter((u: GetUsersResponseDataItem) => u.role === 'Owner')
        .filter(
          (u: GetUsersResponseDataItem) => u.principalType === 'UserIdentity'
        )
        .reduce((acc: any, dataItem: GetUsersResponseDataItem) => {
          const user = users.find(
            (item: GetTenantUsersResponseUser) =>
              item.userIdentityId === dataItem.userId
          )

          if (!user) return acc

          acc.push({
            email: user.email,
            name: user.name
          })

          return acc
        }, [])

      setApiUsers(finalUsers)
    }

    handleGetAndMergeUsers().catch(console.error)
  }, [])

  return {
    apiUsers
  }
}

export const SiteOwnersDialogButton = (props: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { apiUsers } = useApiUsers()

  return (
    <>
      <Button
        className={props.classes.buttonSecondaryBorderWhite}
        variant="contained"
        aria-hidden={!props.isMenuOpen}
        onClick={() => setIsDialogOpen(true)}
      >
        Site owners
      </Button>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '1rem'
          }}
        >
          <Typography variant="h1">Site owners</Typography>
          {apiUsers.map((user, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '3rem'
                }}
              >
                <Typography variant="h6">{user.name}</Typography>
                <Typography>{user.email}</Typography>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </Dialog>
    </>
  )
}
