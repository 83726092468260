/* eslint-disable react/prop-types */
import React from 'react'
import IDatasetReader from './model/IDatasetReader'
import IDatasetWriter from './model/IDatasetWriter'
import { Formik, Form, FieldArray, FormikProps } from 'formik'
import map from 'lodash/map'
import { ReactComponent as Plus } from '@mike/mike-shared-frontend/media/icons/Plus'
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import TextField from '../formik/TextField'
import SelectField from '../formik/SelectField'
import { iconStyle } from '../buttons/iconStyles'
import { useDialogContentStyles } from './styles/datasetUploadStyles'
import ParameterInputField from './ParameterInputField'
import ParameterOutputField from './ParameterOutputField'
import conversionTranslations from './translations/conversionTranslations'
import readerWriterTranslations from './translations/readerWriterTranslations'
import { useConversionStyles } from './styles/useConversionStyles'
import { SRID_PARAM, XYZCOLUMNSSEQUENCE } from '../../helpers/import'
import ContextHelpContainer from '../ContextHelp'
import classNames from 'classnames'
import { ConvertToIcon } from './ConvertToIcon'
import { FILEWRITER } from './model/asIfReaderWriter'
import { dialogMode } from './DatasetConversionDialog'
import { adaptedFileName, resetFileExtension } from './support'
import IProjection from '../../model/IProjection'
import { insertSpaces } from './support'

interface IState {
  writer: string
  reader: string
  name: string
  description: string
}

export interface ITextResources {
  cancel: string
  submit: string
  readerDropDown: string
}

export interface IParameterErrors {
  readerParameters?: string[]
  writerParameters?: string[]
}

interface IProps {
  initialValues: IState
  handleUpload: (values: IState) => void
  handleCancel: () => void
  datasetReaders: IDatasetReader[]
  datasetWriters: IDatasetWriter[]
  mode: dialogMode.UPLOAD | dialogMode.CONVERSION | dialogMode.DOWNLOAD
  showDatasetReadersLoading: boolean
  textResources?: ITextResources
  writersToHide?: string[]
  projections: IProjection[]
  projectionsLoading: boolean
  onProjectionSearchTextChanged: (searchText: string) => void
  searchCoordinateSystemsById: boolean
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('The file needs to have a name'),
  description: Yup.string(),
  reader: Yup.string().required('Current data type needs to be specified'),
  writer: Yup.string().required('Target data type needs to be specified')
})

const DatasetConversion = (props: IProps) => {
  const classes = useConversionStyles()
  const dialogContentClasses = useDialogContentStyles()

  const {
    initialValues,
    handleUpload,
    handleCancel,
    datasetReaders,
    datasetWriters,
    mode,
    showDatasetReadersLoading,
    textResources = {
      cancel: 'Cancel',
      submit: 'Upload & Convert',
      readerDropDown: 'Uploaded file type'
    },
    writersToHide = [],
    projections,
    projectionsLoading,
    onProjectionSearchTextChanged,
    searchCoordinateSystemsById
  } = props

  const inDownloadMode = mode === dialogMode.DOWNLOAD
  const [values, setValues] = React.useState(initialValues)
  React.useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  const [expandDescription, setExpandDescription] = React.useState(false)
  const [resetInputParameter, setResetInputParameter] = React.useState(false)
  const [resetExportParameter, setResetExportParameter] = React.useState(false)

  const formatMessage = React.useCallback((id, defaultValue = '') => {
    const formattedMessage = readerWriterTranslations[id]
      ? readerWriterTranslations[id]
      : insertSpaces(defaultValue)
    return formattedMessage
  }, [])

  const formatTypeMessage = React.useCallback(
    (id, defaultValue = '') => {
      const formattedTypeMessage = [
        formatMessage(id, defaultValue),
        formatMessage(id + '.description', defaultValue)
      ]
      return formattedTypeMessage
    },
    [formatMessage]
  )

  const getAvailableWriters = React.useCallback(
    readerObj =>
      readerObj &&
      readerObj.writers.filter((w: string) => !writersToHide.includes(w)),
    [writersToHide]
  )

  const getWriters = React.useCallback(
    readerObj =>
      readerObj &&
      readerObj.writers
        .filter((w: string) => !writersToHide.includes(w))
        .map((w: string) => {
          const [writerTypeName, writerTypeDesc] = formatTypeMessage(
            `import.writers.${w}`,
            w
          )

          return {
            id: w,
            writerTypeName,
            writerTypeDesc
          }
        }),
    [formatTypeMessage, writersToHide]
  )

  const localizedReaders = React.useMemo(
    () =>
      datasetReaders &&
      datasetReaders.map(el => {
        const fileTypeKey = `import.readers.${el.name}`

        const [fileTypeName, fileTypeDesc] = formatTypeMessage(
          fileTypeKey,
          el.name
        )
        return {
          fileTypeName,
          fileTypeDesc,
          ...el
        }
      }),
    [datasetReaders, formatTypeMessage]
  )

  const reorderedParameters = params => {
    // reorder elements so that SRID parameter is at the beginning
    return params.length > 0
      ? [
          params.find(item => item.name === SRID_PARAM),
          ...params.filter(item => item.name !== SRID_PARAM)
        ]
      : []
  }

  const handleResetInputParameters = React.useCallback(() => {
    setResetInputParameter(false)
  }, [setResetInputParameter])

  const handleResetExportParameters = React.useCallback(() => {
    setResetExportParameter(false)
  }, [setResetExportParameter])

  const validateParameters = React.useCallback(
    ({ reader, readerParameters, writer, writerParameters }) => {
      const errors = {} as IParameterErrors
      if (reader) {
        const readerObj = datasetReaders.find(el => el.id === reader)
        const params = readerObj && readerObj.parameters
        if (params) {
          const requiredParams = params
            .filter(p => p.required === true)
            .map(rp => {
              return rp.name
            })
          if (requiredParams.length > 0) {
            if (!readerParameters) {
              errors.readerParameters = requiredParams
              return errors
            } else {
              for (let i = 0; i <= requiredParams.length; i++) {
                const requiredErrors = Array<string>()
                const requiredParameter: string = requiredParams[i]
                if (!(requiredParameter in readerParameters)) {
                  requiredErrors.push(requiredParams[i])
                }
                if (requiredErrors.length > 0) {
                  errors.readerParameters = requiredErrors
                }
                return errors
              }
            }
          }
        }
      }
      if (writer) {
        const writerObj = datasetWriters.find(el => el.name === writer)
        const params = writerObj && writerObj.parameters
        if (params) {
          const requiredParams = params
            .filter(p => p.required === true && p.dataType !== 'boolean')
            .map(rp => {
              return rp.name
            })
          if (requiredParams.length > 0) {
            if (!writerParameters) {
              errors.writerParameters = requiredParams
              return errors
            } else {
              for (let i = 0; i <= requiredParams.length; i++) {
                const requiredErrors = Array<string>()
                const requiredParameter: string = requiredParams[i]
                if (!(requiredParameter in writerParameters)) {
                  requiredErrors.push(requiredParams[i])
                }
                if (requiredErrors.length > 0) {
                  errors.writerParameters = requiredErrors
                }
                return errors
              }
            }
          }
        }
      }
      return errors
    },
    [datasetReaders, datasetWriters]
  )

  return (
    <Formik
      initialValues={values}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validateParameters}
      onSubmit={handleUpload}
      render={(formikProps: FormikProps<IState>) => {
        const {
          values,
          handleChange,
          setFieldTouched,
          errors,
          setFieldValue
        } = formikProps

        const readerObj = datasetReaders.find(el => el.id === values.reader)
        const writers = values.reader && readerObj ? getWriters(readerObj) : []

        const parameters =
          values.reader && readerObj && readerObj.parameters
            ? reorderedParameters(readerObj.parameters)
            : []

        const datasetWriter =
          values.writer &&
          datasetWriters.find(
            datasetWriter => datasetWriter.name === values.writer
          )

        const writerParameters = datasetWriter && datasetWriter.parameters

        const handleFileTypeChange = e => {
          setFieldValue('readerParameters', null)
          setFieldValue('coordinateSystemId', null)
          setFieldValue(XYZCOLUMNSSEQUENCE, null)
          setResetInputParameter(true)
          setFieldValue('writerParameters', null)
          setResetExportParameter(true)
          setFieldValue('reader', e.target.value)
          const readerObject = datasetReaders.find(
            el => el.id === e.target.value
          )

          /* const getDefaultConversion = (selectedReader, writers) => {
            if (
              selectedReader in defaultWriter &&
              writers.includes(defaultWriter[selectedReader])
            ) {
              return defaultWriter[selectedReader]
            }
          } */

          if (readerObject) {
            const availableWriters = getAvailableWriters(readerObject)
            const count = availableWriters ? availableWriters.length : 0
            if (count > 0) {
              if (count === 1) {
                setFieldValue('writer', availableWriters[0])
              } else {
                setFieldValue(
                  'writer',
                  '' //getDefaultConversion(e.target.value, readerObject.writers)
                )
              }
            }
          } else {
            setFieldValue('writer', '')
          }
        }

        const handleSaveAsChange = e => {
          setFieldValue('writerParameters', null)
          setResetExportParameter(true)
          const selectedWriter = e.target.value
          setFieldValue('writer', selectedWriter)
          const saveAsName = values.name ? values.name : initialValues.name
          if (selectedWriter === FILEWRITER) {
            setFieldValue(
              'name',
              resetFileExtension(initialValues.name, saveAsName)
            )
          } else {
            const fileNameWithAdaptedExtension = adaptedFileName(
              saveAsName,
              selectedWriter
            )
            fileNameWithAdaptedExtension &&
              setFieldValue('name', fileNameWithAdaptedExtension)
          }
        }

        const fileWriterAndSRID =
          values.writer === FILEWRITER &&
          parameters &&
          parameters.filter(p => p.name === SRID_PARAM).length > 0

        const change = (name: string, e: any) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form className={dialogContentClasses.form}>
            <MuiDialogContent className={dialogContentClasses.dialogContent}>
              <Grid container>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      id="name"
                      name="name"
                      value={values.name}
                      label={conversionTranslations.name}
                      fullWidth
                      onChange={change.bind(null, 'name')}
                      required
                    />
                  </Grid>

                  {!inDownloadMode && (
                    <Grid
                      item
                      xs={12}
                      className={classNames(
                        classes.marginTop,
                        classes.marginBottom
                      )}
                    >
                      <Collapse
                        in={!expandDescription}
                        timeout="auto"
                        unmountOnExit
                      >
                        <MikeButton
                          onClick={() => {
                            setExpandDescription(true)
                          }}
                          buttontype="text"
                          className={classes.plusButton}
                        >
                          <Plus className={iconStyle} />
                          {conversionTranslations.description}
                        </MikeButton>
                      </Collapse>
                      <Collapse
                        in={expandDescription}
                        timeout="auto"
                        unmountOnExit
                      >
                        <TextField
                          id="description"
                          name="description"
                          value={values.description}
                          label={conversionTranslations.description}
                          fullWidth
                          onChange={change.bind(null, 'description')}
                        />
                      </Collapse>
                    </Grid>
                  )}
                </Grid>
                <Grid container className={classes.marginTop}>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {conversionTranslations.conversionOptions}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item xs={5}>
                      <SelectField
                        id="reader"
                        name="reader"
                        value={values.reader}
                        label={textResources.readerDropDown}
                        valueField="id"
                        primaryField="fileTypeName"
                        secondaryField="fileTypeDesc"
                        items={localizedReaders}
                        fullWidth
                        required
                        loading={showDatasetReadersLoading}
                        disabled={
                          !localizedReaders || localizedReaders.length === 0
                        }
                        hideIcon={
                          !localizedReaders || localizedReaders.length === 1
                        }
                        onChange={handleFileTypeChange}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.marginTopArrow}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <ConvertToIcon />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={fileWriterAndSRID ? 4 : 5}>
                      <SelectField
                        id="writer"
                        name="writer"
                        value={values.writer}
                        required
                        label={conversionTranslations.writerDropDown}
                        valueField="id"
                        primaryField="writerTypeName"
                        secondaryField="writerTypeDesc"
                        items={writers}
                        fullWidth
                        disabled={!writers || writers.length === 0}
                        hideIcon={!writers || writers.length === 1}
                        onChange={handleSaveAsChange}
                      />
                    </Grid>
                    {fileWriterAndSRID && (
                      <Grid item xs={1}>
                        <ContextHelpContainer
                          helpTexts={
                            fileWriterAndSRID
                              ? [conversionTranslations.fileWriterAndSRIDHelp]
                              : []
                          }
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container>
                    {parameters && parameters.length > 0 && (
                      <Grid item xs={5}>
                        <FieldArray
                          name="parameters"
                          render={() => (
                            <div>
                              {/* <Grid container alignItems="flex-start"> */}
                              {map(parameters, (parameter, index) => (
                                <ParameterInputField
                                  disabled={
                                    fileWriterAndSRID &&
                                    parameter.name !== SRID_PARAM
                                  }
                                  handleReset={handleResetInputParameters}
                                  parameter={parameter}
                                  parameterErrors={errors as IParameterErrors}
                                  reset={resetInputParameter}
                                  key={index}
                                  projections={projections}
                                  projectionsLoading={projectionsLoading}
                                  onProjectionSearchTextChanged={
                                    onProjectionSearchTextChanged
                                  }
                                  searchCoordinateSystemsById={
                                    searchCoordinateSystemsById
                                  }
                                />
                              ))}
                              {/* </Grid> */}
                            </div>
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={2} />
                    {writerParameters && writerParameters.length > 0 && (
                      <Grid item xs={5}>
                        <FieldArray
                          name="writerParameters"
                          render={() => (
                            <div>
                              <Grid container alignItems="flex-start">
                                {map(
                                  writerParameters,
                                  (writerParameter, index) => (
                                    <ParameterOutputField
                                      handleReset={handleResetExportParameters}
                                      parameter={writerParameter}
                                      parameterErrors={
                                        errors as IParameterErrors
                                      }
                                      reset={resetExportParameter}
                                      key={index}
                                    />
                                  )
                                )}
                              </Grid>
                            </div>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </MuiDialogContent>
            <DialogActions className={dialogContentClasses.dialogActions}>
              <MikeButton
                onClick={handleCancel}
                buttontype="text"
                className={dialogContentClasses.button}
              >
                {textResources.cancel}
              </MikeButton>
              <MikeButton
                buttontype="primary"
                className={dialogContentClasses.button}
                type="submit"
              >
                {textResources.submit}
              </MikeButton>
            </DialogActions>
          </Form>
        )
      }}
    />
  )
}

export default DatasetConversion
